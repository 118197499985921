import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { HeadingContainer, Text } from '../components/common/typography';
import SEO from '../components/seo';
import AppLayout from '../components/layouts/appLayout';
import bp, { MOBILE_DEVICE } from '../components/common/breakpoints';
import SalesforceSVG from '../images/case-studies/salesforce-logo.inline.svg';
import CloudSaveSVG from '../images/case-studies/cloud-save.inline.svg';
import RoboticBugSVG from '../images/case-studies/robotic-bug.inline.svg';
import JavaSVG from '../images/assets/cofblack.inline.svg';
import PdfSVG from '../images/icons/resources/pdf-round.svg';
import FinOpsSVG from '../images/assets/finops.inline.svg';
import { motion } from 'framer-motion';
import { MainButton, ButtonNoStyleAlt } from '../components/common/button';
import salesForcePdf from '../pdf/Alio_Salesforce_CRM_implementation_Final.pdf';
import cloudMigrationPdf from '../pdf/Cloud_Migration_White_Paper.pdf';
import QaPdf from '../pdf/Alio_QA_Automation.pdf';
import JavaPDF from '../pdf/Java_Best_Practices_Whitepaper.pdf';
import FinopsPDF from '../pdf/FinOps_Whitepaper.pdf';
import { Link } from 'gatsby';
import { Checkbox } from '@material-ui/core';
import carlosRomero from '../images/assets/authors/carlos_romero.jpeg';
import puneetJain from '../images/assets/authors/puneet_jain.png';
import salvadorHernandez from '../images/assets/authors/salvador_hernandez.jpeg';
import saraiAragon from '../images/assets/authors/sarai_aragon.jpeg';
import ernestoRSV from '../images/assets/authors/ernesto_rsv.jpg';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  BLUE_COLOR,
  BLACK_COLOR_RGB,
} from '../components/common/color';
import { useMediaQuery } from '@react-hook/media-query';

const logos = {
  salesforce: <SalesforceSVG />,
  cloudsave: <CloudSaveSVG />,
  roboticbug: <RoboticBugSVG />,
  java: <JavaSVG />,
  cloudicon: <FinOpsSVG />,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 6rem;
  box-sizing: border-box;

  @media screen and (min-width: ${bp[2]}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
`;

const Content = styled.div`
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 10px 10px 20px ${BLACK_COLOR_RGB(0.1)};
  border-radius: 10px;

  @media screen and (min-width: ${bp[1]}px) {
    width: 90%;
    padding: 2rem;
  }
`;

const Paper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  ${bp[1]} {
    grid-template-columns: 1fr 6fr;
    grid-template-rows: 60px auto auto 1fr auto auto;
    column-gap: 2rem;
    row-gap: 3rem;
  }

  ${bp[3]} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    column-gap: 3rem;
  }
`;

const IconContainer = styled.div`
  align-self: flex-end;
  max-width: 54.66px;
  min-width: 54.66px;
  max-height: 54.66px;
  min-height: 54.66px;
`;

const Heading = styled.h2`
  position: relative;
  align-self: flex-start; /* Ensures left alignment */
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 3rem;
    height: 3px;
    background-color: ${PRIMARY_COLOR};
  }
`;

const SubTitle = styled.h2`
  position: relative;
  align-self: center;
  margin: 20px;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;

  @media screen and (max-width: ${MOBILE_DEVICE}px) {
    font-size: 1.5rem;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: ${bp[3]}px) {
    flex-direction: row; /* Side by side layout for desktop */
  }
`;

const KeyBlock = styled.div`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  align-items: flex-start;
  grid-row: 4/5;

  ${bp[1]} {
    grid-template-columns: unset;
    grid-row: 2/4;
    align-items: flex-start;
    justify-items: start;
  }

  ${bp[3]} {
    grid-template-columns: repeat(3, 1fr);
    grid-row: auto;
    justify-items: start;
    align-items: start;
  }
`;


const KeyItem = styled.div`
  display: grid;
  row-gap: 0.5rem;
  padding: 1rem;
  border: 1px solid ${BLACK_COLOR_RGB(0.1)};
  border-radius: 8px;
  background-color: ${WHITE_COLOR};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

const KeyContent = styled.div`
  padding-left: 0.5rem;
  color: ${BLACK_COLOR_RGB(0.6)};
  font-size: 0.75rem;

  ${bp[3]} {
    font-size: 0.75rem;
  }
`;


const KeyTitle = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 0.825rem;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;

  ${bp[3]} {
    font-size: 0.825rem;
  }
`;


const PaperTopic = styled.h4`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: ${BLACK_COLOR_RGB(0.8)};
`;

const AuthorHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-left: 10px;
  font-weight: bold;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: ${BLACK_COLOR_RGB(0.8)};
`;

const ContentSection = styled.div`
  color: ${BLACK_COLOR_RGB(0.7)};
  width: 100%;

  & > p {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${bp[3]}px) {
    width: 100%; /* Adjust width to fit next to points */
  }
`;

const Form = styled(motion.form)`
  height: fit-content;
  display: grid;
  row-gap: 2rem;
  width: 100%;

  ${bp[3]} {
    height: 100%;
    width: 50%; /* Use full width of the allocated column */
  }
`;

const Label = styled(motion.label)`
  display: grid;
  row-gap: 0.75rem;
  font-family: 'source code pro';
  color: ${BLACK_COLOR};
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  max-height: 3rem;

  @media screen and (max-width: 598px) {
    max-height: ${(props) => (props.big && '5rem') || '3rem'};
  }
`;

const LabelAlt = styled(motion.label)`
  display: grid;
  row-gap: 0.75rem;
  font-family: 'source code pro';
  color: ${BLACK_COLOR};
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  max-height: 3rem;

  @media screen and (max-width: 598px) {
    max-height: ${(props) => (props.big && '5rem') || '3rem'};
  }
`;


export const TextAlt = styled(motion.label)`
  margin: 0;
  font-weight: normal;
  font-size: 0.9rem;
  font-family: var(--secondary-font);
`;

const Input = styled.input`
  color: ${BLACK_COLOR};
  background-color: ${BLACK_COLOR};
  color: ${WHITE_COLOR};
  border-radius: 4px;
  height: 2.5rem;
  padding: 0px 1rem;
  border-bottom: 1px solid ${BLACK_COLOR_RGB(0.8)};
  outline-color: ${WHITE_COLOR};
`;

const PrivacyPolicy = styled(Link)`
  margin: 0;
  padding: 0;
  font-family: 'Manrope';
  font-size: 1rem;
  color: ${BLUE_COLOR};
  cursor: pointer;
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to the left */
  padding: 1rem;

  @media screen and (min-width: ${bp[1]}px) {
    flex-direction: row;
    align-items: flex-start;
    padding-left: 20px;
  }
`;


const AuthorName = styled.label`
  font-size: 1.25rem;
  font-weight: bold;
  padding-left: 10px;
`;

const AuthorTitle = styled.label`
  font-size: 1.25rem;
  padding-left: 10px;
`;

const CardAnimate = {
  offscreen: { x: -10, opacity: 0 },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 2,
    },
  },
}

const containerFlex = {
  display: 'flex',
}

const containerFlexColumn = {
  display: 'flex',
  flexDirection: 'column'
}

const papers = [
  {
    'title': 'Salesforce CRM implementation to streamline your business and increase profitability',
    'icon': 'salesforce',
    'points': [
      'Economics of a CRM',
      'Lead Nurturing process',
      'Customization of the CRM Salesforce',
      'Security Features',
      'Importance of CRMs'
    ],
    'paragraphs': [
      'A customer relationships managements system (CRM) is ideal for tracking and keeping visibility \
      of your sales process throughout your organization. CRMs store potential customer data and lets \
      you follow your particular sales process with the possibility of maturing your process through \
      the CRM customizable work flows. As business grows the complexity of information and variety of \
      customer data increases so it is best to have a good system to store and view all your customer \
      and potential customer data to identify also further growth potential within your sales process. \
      Other areas that can be improved with the data you generate and store in your CRM are Marketing \
      targeting to gain more potential clients also called leads and also customer service provided to \
      your actual won leads turned into customer'
    ],
    'file': salesForcePdf,
    'fileName': 'Alio_Salesforce_CRM_implementation_Final.pdf',
    'author': {
      'picture': salvadorHernandez,
      'name': 'Salvador Hernandez',
      'title': 'Senior Salesforce Developer'
    }
  },
  {
    'title': 'Cloud Migration to optimize Cost, Performance, Security and Reliability',
    'icon': 'cloudsave',
    'points': [
      'Achieving Scalability',
      'Improved Customer Experience',
      'Continuous Improvement',
      'Fail Safes and Monitoring',
      'Migrating for Resiliency',
      'Safety and Productivity'

    ],
    'paragraphs': [
      'As of today many companies are still considering whether to move some or all of their workloads \
      to a cloud environment. In 2021, more than 70% of companies have now migrated at least some workloads \
      into the public cloud, according to Gartner research (*). There are important benefits related to \
      this migration and one of the main are cost optimization as you are in more control of what workloads \
      run and can schedule based on paramaterizations the amount of compute resources utilized to meet \
      specific demand requirements.',
      'The resource demand is depending on the size of a companies operation and usage online, many ecommerce, \
      hospitals, schools, financial institutions, etc rely on the internet to carry on their business and \
      having a reliable and secure infrastructure has never been more important. This is what can be achieved \
      through a thought out and in some instances phased transition of workloads to the cloud.'
    ],
    'file': cloudMigrationPdf,
    'fileName': 'Cloud_Migration_White_Paper.pdf',
    'author': {
      'picture': carlosRomero,
      'name': 'Carlos Romero',
      'title': 'Senior Java & DevOps Engineer'
    }
  },
  {
    'title': 'QA Automation to bring higher Quality and Resiliency into your Development Process',
    'icon': 'roboticbug',
    'points': [
      'Scaling your potential',
      'Agility into your lifecycle',
      'Economics of automation',
      'Key components of QA automation',
      'Best Practices',
      'Future of QA Automation'
    ],
    'paragraphs': [
      'In the rapidly evolving world of software development, maintaining high-quality standards \
      while accelerating delivery cycles is paramount. Quality Assurance (QA) Automation presents \
      a powerful solution to meet these demands. This whitepaper explores the strategies, benefits, \
      and best practices of implementing QA Automation to scale your potential and enhance \
      software quality and delivery.'
    ],
    'file': QaPdf,
    'fileName': 'Alio_QA_Automation.pdf',
    'author': {
      'picture': puneetJain,
      'name': 'Puneet Jain',
      'title': 'Senior QA Automation Engineer'
      }
    },
    {
      'title': 'Java Best Practices',
      'icon': 'java',
      'points': [
        'Best Practices',
        'Java Versions',
        'Tuning Ideas',
        'Tuning for Throughput',
        'Monitoring and Profiling',
        'Coding for Performance'
      ],
      'paragraphs': [
        'Many systems today are built and rely on Java technology as it possesses a rich ecosystem  \
         and several scalability and security features that still attract many companies to adopt it \
         Not to mention also the fact that Java is a multiplatform supporting technology so your applications \
         can run on any operating system/platform. There are many years behind the development and growth/maturity \
         of Java and with this several optimization and best practice items that are worth considering which are described in this white paper.'
      ],
      'file': JavaPDF,
      'fileName': 'Java_Best_Practices_Whitepaper.pdf',
      'author': {
        'picture': saraiAragon,
        'name': 'Sarai Aragon',
        'title': 'Senior Java Engineer'
      }
    },
      {
        'title': 'FinOps for Cloud Cost Optimization',
        'icon': 'cloudicon',
        'points': [
          'FinOps Purpose',
          'Cost Structures & Pricing Models',
          'Cost Components',
          'Right Sizing Resources',
          'Resource Management & Monitoring'
        ],
        'paragraphs': [
          'In todays rapidly evolving cloud landscape, managing cloud costs has become a top priority for organizations of all sizes. \
          As more businesses transition to cloud infrastructure, they face the growing challenge of optimizing their spending while \
          maintaining operational efficiency. This is where the FinOps Framework by FinOps Foundation comes into play—a strategic framework \
          designed to help organizations gain visibility and control over their cloud expenses.'
        ],
        'file': FinopsPDF,
        'fileName': 'FinOps_Whitepaper.pdf',
        'author': {
          'picture': ernestoRSV,
          'name': 'Ernesto Ruy Sanchez',
          'title': 'Business Development & Senior DevOps Engineer'
        }  
    }
  
]

const WhitePaperPage = () => {
  const [displayForm, setDisplayForm] = useState(true)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [privacy, setPrivacy] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    privacy: false
  })
  const { search } = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(search)
    const message = params.get('message')
    if (message === 'thank-you') {
      setDisplayForm(false)
    } else {
      setDisplayForm(true)
    }
  }, [search])

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleSubmitHubspot = async (e) => {
    const formElement = e.target
    e.preventDefault()

    try {
      const xhr = new XMLHttpRequest()
      const url =
        'https://api.hsforms.com/submissions/v3/integration/submit/5727045/d29faeaf-87ea-417f-9386-02d84b16f583'
      const data = {
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: '0-2/timeline',
            value: '1 - 3 months'
          },
          {
            name: 'country',
            value: 'none'
          }
        ],
        context: {
          pageUri: 'www.alioit.com/white-paper',
          pageName: 'White Paper - Alio IT',
        },
      }

      const finalData = JSON.stringify(data)

      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(finalData)

      setDisplayForm(false)
      setEmail('')
      setFirstName('')
      setLastName('')
      setPrivacy(false)
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formElement = e.target

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': formElement.getAttribute('name'),
          ...formData,
        }),
      })

      navigate(formElement.getAttribute('action'))
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handlePrivacy = () => {
    setFormData((prev) => ({ ...prev, ['privacy']: !privacy }))
    setPrivacy(!privacy)
  }

  const downloadPDF = (PdfFile, fileName) => {
    fetch(`${PdfFile}`).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      })
    })
  };
  const matchMobile = useMediaQuery(`(min-width: ${MOBILE_DEVICE}px)`)
  return (
    <AppLayout>
      <SEO title="white-paper" canonical="white-paper" />
      <HeadingContainer
        title="White Papers"
      />
      <SubTitle>Sharing knowledge and Building Futures Together - Whitepapers</SubTitle>
      <Container>
        {papers.map((paper, indexPa) => (
          <Content key={indexPa}>
            <Paper>
              <IconContainer>{logos[paper.icon]}</IconContainer>
              <Heading>{paper.title}</Heading>
              <KeyBlock>
                <KeyItem>
                  <KeyTitle>Key Points</KeyTitle>
                  {paper.points.map((point, indexPo) => (
                    < KeyContent key={indexPo}>
                      {point}
                    </KeyContent>
                  ))}
                </KeyItem>
              </KeyBlock>
              <ContentSection>
                <PaperTopic>About</PaperTopic>
                {paper.paragraphs.map((paragraph, indexPh) => (
                  <Text key={indexPh}>
                    {paragraph}
                  </Text>
                ))}
              </ContentSection>
              <div style={matchMobile ? containerFlex : containerFlexColumn} >
                {displayForm && (<Form
                  action="/white-paper?message=thank-you"
                  name="whitePaper"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  onSubmit={handleSubmitHubspot}
                  variants={CardAnimate}
                  transition={{ staggerChildren: 0.3 }}
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="whitePaper" />
                  <Label>
                    Email *
                    <Input
                      name="email"
                      type="email"
                      required
                      onBlur={handleChange}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Label>
                  <Label>
                    First name *
                    <Input
                      name="firstName"
                      required
                      onBlur={handleChange}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </Label>
                  <Label>
                    Last name *
                    <Input
                      name="lastName"
                      required
                      onBlur={handleChange}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </Label>
                  <div style={{ float: 'right', marginTop: '5px' }}>
                    <LabelAlt>Dont worry your information is *private*. Please fill out our form below to gain access to our free whitepapers.</LabelAlt>
                    <Checkbox
                      name="privacy"
                      required
                      checked={privacy}
                      color="default"
                      onChange={(e) => handlePrivacy(e)}
                    />
                    <TextAlt>I agree to receive other communications from Alio IT.</TextAlt>
                    <Text>
                      You can unsubscribe from these communications at any time. For more information, please review our
                      <PrivacyPolicy to={'/privacy-policy'}> Privacy Policy.</PrivacyPolicy>
                    </Text>
                  </div>
                  <MainButton icon={'file-text'} type={'submit'}>
                    Submit
                  </MainButton>
                </Form>)}
                <div>
                  <AuthorContainer>
                    <div>
                      <img src={paper.author.picture} alt="" style={{ maxWidth: "140px" }} />
                    </div>
                    <div>
                      <AuthorHeader>Contributor</AuthorHeader>
                      <AuthorName>{paper.author.name}</AuthorName>
                      <br></br>
                      <AuthorTitle>{paper.author.title}</AuthorTitle>
                    </div>
                  </AuthorContainer>
                </div>
              </div>
              {!displayForm && (
                <div>
                  <ButtonNoStyleAlt
                    key={indexPa}
                    onClick={(e) => downloadPDF(paper.file, paper.fileName)}
                  >
                    <PdfSVG style={{ minWidth: "25px", minHeight: "25px" }} />
                    Download PDF
                  </ButtonNoStyleAlt>
                </div>
              )}
            </Paper>
          </Content>
        ))}
      </Container>
    </AppLayout >
  )
}

export default WhitePaperPage
